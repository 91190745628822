define("discourse/plugins/discourse-docs/discourse/controllers/docs-index", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-docs/discourse/models/docs", "@ember/application", "discourse-common/lib/get-url", "I18n"], function (_exports, _controller, _decorators, _object, _computed, _docs, _application, _getUrl, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SHOW_FILTER_AT = 10;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: {
      ascending: "ascending",
      filterCategories: "category",
      filterTags: "tags",
      filterSolved: "solved",
      orderColumn: "order",
      searchTerm: "search",
      selectedTopic: "topic"
    },
    application: (0, _controller.inject)(),
    isLoading: false,
    isLoadingMore: false,
    isTopicLoading: false,
    filterTags: null,
    filterCategories: null,
    filterSolved: false,
    searchTerm: null,
    selectedTopic: null,
    topic: null,
    expandedFilters: false,
    ascending: null,
    orderColumn: null,
    showCategoryFilter: (0, _computed.gt)("categories.length", SHOW_FILTER_AT),
    categoryFilter: "",
    categorySort: {},
    showTagFilter: (0, _computed.gt)("tags.length", SHOW_FILTER_AT),
    tagFilter: "",
    tagSort: {},
    loadMoreUrl: (0, _computed.alias)("model.topics.load_more_url"),
    categories: (0, _computed.readOnly)("model.categories"),
    topics: (0, _computed.alias)("model.topics.topic_list.topics"),
    tags: (0, _computed.readOnly)("model.tags"),
    topicCount: (0, _computed.alias)("model.topic_count"),
    emptyResults: (0, _computed.equal)("topicCount", 0),
    _setupFilters() {
      if (!this.site.mobileView) {
        this.set("expandedFilters", true);
      }
      this.setProperties({
        categorySort: {
          type: "alpha",
          // alpha or numeric
          direction: "asc" // asc or desc
        },
        tagSort: {
          type: "numeric",
          // or alpha
          direction: "desc" // or asc
        },
        orderColumn: "title",
        ascending: true
      });
    },
    sortedCategories(categories, categorySort, filter) {
      let {
        type,
        direction
      } = categorySort;
      if (type === "numeric") {
        categories = categories.sort((a, b) => a.count - b.count);
      } else {
        categories = categories.sort((a, b) => {
          const first = this.site.categories.findBy("id", a.id).name.toLowerCase(),
            second = this.site.categories.findBy("id", b.id).name.toLowerCase();
          return first.localeCompare(second);
        });
      }
      if (direction === "desc") {
        categories = categories.reverse();
      }
      if (this.showCategoryFilter) {
        return categories.filter(category => {
          let categoryData = this.site.categories.findBy("id", category.id);
          return categoryData.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || categoryData.description_excerpt && categoryData.description_excerpt.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
      }
      return categories;
    },
    categorySortNumericIcon(catSort) {
      if (catSort.type === "numeric" && catSort.direction === "asc") {
        return "sort-numeric-down";
      }
      return "sort-numeric-up";
    },
    categorySortAlphaIcon(catSort) {
      if (catSort.type === "alpha" && catSort.direction === "asc") {
        return "sort-alpha-down";
      }
      return "sort-alpha-up";
    },
    sortedTags(tags, tagSort, filter) {
      let {
        type,
        direction
      } = tagSort;
      if (type === "numeric") {
        tags = tags.sort((a, b) => a.count - b.count);
      } else {
        tags = tags.sort((a, b) => {
          return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
        });
      }
      if (direction === "desc") {
        tags = tags.reverse();
      }
      if (this.showTagFilter) {
        return tags.filter(tag => {
          return tag.id.toLowerCase().indexOf(filter.toLowerCase()) > -1;
        });
      }
      return tags;
    },
    tagSortNumericIcon(tagSort) {
      if (tagSort.type === "numeric" && tagSort.direction === "asc") {
        return "sort-numeric-down";
      }
      return "sort-numeric-up";
    },
    tagSortAlphaIcon(tagSort) {
      if (tagSort.type === "alpha" && tagSort.direction === "asc") {
        return "sort-alpha-down";
      }
      return "sort-alpha-up";
    },
    noContent(topics, isSearching, filterSolved) {
      const filtered = isSearching || filterSolved;
      return this.topicCount === 0 && !filtered;
    },
    canLoadMore(loadMoreUrl) {
      return loadMoreUrl === null ? false : true;
    },
    isSearching(searchTerm) {
      return !!searchTerm;
    },
    isSearchingOrFiltered(isSearching, filterSolved) {
      return isSearching || filterSolved;
    },
    canFilterSolved() {
      return this.siteSettings.solved_enabled && this.siteSettings.docs_add_solved_filter;
    },
    filtered(filterTags) {
      return !!filterTags;
    },
    shouldShowTags() {
      return this.siteSettings.tagging_enabled;
    },
    emptyState() {
      let body = _I18n.default.t("docs.no_docs.body");
      if (this.docsCategoriesAndTags.length) {
        body += _I18n.default.t("docs.no_docs.to_include_topic_in_docs");
        body += ` (${this.docsCategoriesAndTags.join(", ")}).`;
      } else if (this.currentUser.staff) {
        const setUpPluginMessage = _I18n.default.t("docs.no_docs.setup_the_plugin", {
          settingsUrl: (0, _getUrl.default)("/admin/site_settings/category/plugins?filter=plugin:discourse-docs")
        });
        body += ` ${setUpPluginMessage}`;
      }
      return {
        title: _I18n.default.t("docs.no_docs.title"),
        body: body.htmlSafe()
      };
    },
    docsCategoriesAndTags(docsCategories, docsTags) {
      return docsCategories.concat(docsTags);
    },
    docsCategories() {
      if (!this.siteSettings.docs_categories) {
        return [];
      }
      return this.siteSettings.docs_categories.split("|").map(c => {
        const id = parseInt(c, 10);
        return this.site.categories.findBy("id", id).name;
      });
    },
    docsTags() {
      if (!this.siteSettings.docs_tags) {
        return [];
      }
      return this.siteSettings.docs_tags.split("|").map(t => `#${t}`);
    },
    toggleCategorySort(newType) {
      let {
        type,
        direction
      } = this.categorySort;
      this.set("categorySort", {
        type: newType,
        direction: type === newType ? direction === "asc" ? "desc" : "asc" : "asc"
      });
    },
    toggleTagSort(newType) {
      let {
        type,
        direction
      } = this.tagSort;
      this.set("tagSort", {
        type: newType,
        direction: type === newType ? direction === "asc" ? "desc" : "asc" : "asc"
      });
    },
    setSelectedTopic(topicId) {
      this.set("selectedTopic", topicId);
      window.scrollTo(0, 0);
    },
    onChangeFilterSolved(solvedFilter) {
      this.set("filterSolved", solvedFilter);
    },
    updateSelectedTags(tag) {
      let filter = this.filterTags;
      if (filter && filter.includes(tag.id)) {
        filter = filter.replace(tag.id, "").replace(/^\|+|\|+$/g, "");
      } else if (filter) {
        filter = `${filter}|${tag.id}`;
      } else {
        filter = tag.id;
      }
      this.setProperties({
        filterTags: filter,
        selectedTopic: null
      });
    },
    updateSelectedCategories(category) {
      let filter = this.filterCategories;
      if (filter && filter.includes(category.id)) {
        filter = filter.replace(category.id, "").replace(/^\|+|\|+$/g, "");
      } else if (filter) {
        filter = `${filter}|${category.id}`;
      } else {
        filter = category.id;
      }
      this.setProperties({
        filterCategories: filter,
        selectedTopic: null
      });
      return false;
    },
    performSearch(term) {
      if (term === "") {
        this.set("searchTerm", null);
        return false;
      }
      if (term.length < this.siteSettings.min_search_term_length) {
        return false;
      }
      this.setProperties({
        searchTerm: term,
        selectedTopic: null
      });
    },
    sortBy(column) {
      const order = this.orderColumn;
      const ascending = this.ascending;
      if (column === "title") {
        this.set("orderColumn", "title");
        /* } else if (column === "activity") {
          this.set("orderColumn", "activity"); */
      }
      if (!ascending && order) {
        this.set("ascending", true);
      } else {
        this.set("ascending", "");
      }
    },
    loadMore() {
      if (this.canLoadMore && !this.isLoadingMore) {
        this.set("isLoadingMore", true);
        _docs.default.loadMore(this.loadMoreUrl).then(result => {
          const topics = this.topics.concat(result.topics.topic_list.topics);
          this.setProperties({
            topics,
            loadMoreUrl: result.topics.load_more_url || null,
            isLoadingMore: false
          });
        });
      }
    },
    toggleFilters() {
      if (!this.expandedFilters) {
        this.set("expandedFilters", true);
      } else {
        this.set("expandedFilters", false);
      }
    },
    returnToList() {
      this.set("selectedTopic", null);
      (0, _application.getOwner)(this).lookup("router:main").transitionTo("docs");
    }
  }, [["method", "_setupFilters", [(0, _decorators.on)("init")]], ["method", "sortedCategories", [(0, _decorators.default)("categories", "categorySort", "categoryFilter")]], ["method", "categorySortNumericIcon", [(0, _decorators.default)("categorySort")]], ["method", "categorySortAlphaIcon", [(0, _decorators.default)("categorySort")]], ["method", "sortedTags", [(0, _decorators.default)("tags", "tagSort", "tagFilter")]], ["method", "tagSortNumericIcon", [(0, _decorators.default)("tagSort")]], ["method", "tagSortAlphaIcon", [(0, _decorators.default)("tagSort")]], ["method", "noContent", [(0, _decorators.default)("topics", "isSearching", "filterSolved")]], ["method", "canLoadMore", [(0, _decorators.default)("loadMoreUrl")]], ["method", "isSearching", [(0, _decorators.default)("searchTerm")]], ["method", "isSearchingOrFiltered", [(0, _decorators.default)("isSearching", "filterSolved")]], ["method", "canFilterSolved", [_decorators.default]], ["method", "filtered", [(0, _decorators.default)("filterTags")]], ["method", "shouldShowTags", [(0, _decorators.default)()]], ["method", "emptyState", [(0, _decorators.default)()]], ["method", "docsCategoriesAndTags", [(0, _decorators.default)("docsCategories", "docsTags")]], ["method", "docsCategories", [(0, _decorators.default)()]], ["method", "docsTags", [(0, _decorators.default)()]], ["method", "toggleCategorySort", [_object.action]], ["method", "toggleTagSort", [_object.action]], ["method", "setSelectedTopic", [_object.action]], ["method", "onChangeFilterSolved", [_object.action]], ["method", "updateSelectedTags", [_object.action]], ["method", "updateSelectedCategories", [_object.action]], ["method", "performSearch", [_object.action]], ["method", "sortBy", [_object.action]], ["method", "loadMore", [_object.action]], ["method", "toggleFilters", [_object.action]], ["method", "returnToList", [_object.action]]]));
});